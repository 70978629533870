[data-theme="dark"] {
    --color-primary-50:  hsl(231, 44%, 94%);
    --color-primary-100: hsl(232, 45%, 84%);
    --color-primary-200: hsl(231, 44%, 74%);
    --color-primary-300: hsl(230, 44%, 64%);
    --color-primary-400: hsl(231, 44%, 56%);
    --color-primary-500: hsl(231, 48%, 48%);
    --color-primary-600: hsl(232, 50%, 45%);
    --color-primary-700: hsl(232, 54%, 41%);
    --color-primary-800: hsl(233, 57%, 37%);
    --color-primary-900: hsl(235, 66%, 30%);
    --color-primary-1000:hsl(240, 100%, 16%);
    --color-primary-main: rgba(26, 35, 126, 1);
    --color-primary-dark: hsla(235, 66%, 21%, 1);

    --color-secondary-50:  hsl(187, 71%, 93%);
    --color-secondary-100: hsl(187, 71%, 82%);
    --color-secondary-200: hsl(187, 71%, 71%);
    --color-secondary-300: hsl(187, 71%, 59%);
    --color-secondary-400: hsl(187, 71%, 53%);
    --color-secondary-500: hsl(187, 100%, 42%);
    --color-secondary-600: hsl(187, 100%, 38%);
    --color-secondary-700: hsl(187, 100%, 33%);
    --color-secondary-800: hsl(187, 100%, 28%);
    --color-secondary-900: hsl(187, 100%, 20%);
    --color-secondary-1000:hsl(187, 100%, 11%);
    --color-secondary-main: var(--color-secondary-500);

    --color-base-50: white;
    --color-base-100: hsl(234, 27%, 90%);
    --color-base-200: hsl(234, 27%, 80%);
    --color-base-300: hsl(234, 27%, 70%);
    --color-base-400: hsl(234, 27%, 60%);
    --color-base-500: hsl(234, 27%, 50%);
    --color-base-600: hsl(234, 27%, 40%);
    --color-base-700: hsl(234, 27%, 28%);
    --color-base-800: hsl(234, 27%, 20%);
    --color-base-900: hsl(234, 27%, 15%);
    --color-base-1000: hsl(234, 27%, 10%);

    --color-positive-100: hsl(168, 75%, 95%);
    --color-positive-200: hsl(168, 75%, 90%);
    --color-positive-300: hsl(168, 75%, 83%);
    --color-positive-400: hsl(168, 76%, 48%);
    --color-positive-500: hsl(168, 76%, 45%);
    --color-positive-600: hsl(168, 76%, 41%);
    --color-positive-700: hsl(168, 66%, 25%);
    --color-positive-800: hsl(168, 66%, 20%);
    --color-positive-900: hsl(163, 80%, 15%);

    --color-negative-100: hsl(8, 85%, 97%);
    --color-negative-200: hsl(8, 85%, 93%);
    --color-negative-300: hsl(8, 85%, 89%);
    --color-negative-400: hsl(8, 78%, 67%);
    --color-negative-500: hsl(8, 78%, 61%);
    --color-negative-600: hsl(8, 78%, 55%);
    --color-negative-700: hsl(8, 68%, 38%);
    --color-negative-800: hsl(8, 68%, 34%);
    --color-negative-900: hsl(8, 68%, 30%);

    // --color-info-100: hsl(283, 90%, 96%);
    // --color-info-283: hsl(283, 90%, 92%);
    // --color-info-300: hsl(283, 90%, 88%);
    // --color-info-400: hsl(283, 40%, 55%);
    // --color-info-500: hsl(283, 40%, 50%);
    // --color-info-600: hsl(283, 40%, 45%);
    // --color-info-700: hsl(283, 66%, 28%);
    // --color-info-800: hsl(283, 66%, 25%);
    // --color-info-900: hsl(283, 66%, 22%);

    --color-warning-100: hsl(45, 80%, 95%);
    --color-warning-200: hsl(45, 80%, 90%);
    --color-warning-300: hsl(45, 80%, 85%);
    --color-warning-400: hsl(45, 90%, 55%);
    --color-warning-500: hsl(45, 90%, 48%);
    --color-warning-600: hsl(45, 90%, 45%);
    --color-warning-700: hsl(45, 90%, 31%);
    --color-warning-800: hsl(45, 90%, 27%);
    --color-warning-900: hsl(45, 90%, 27%);

    --color-grey-90: rgba(33, 33, 33, 1);
    --color-grey-80: rgba(66, 66, 66, 1);
    --color-grey-70: rgba(97, 97, 97, 1);
    --color-grey-60: rgba(117, 117, 117, 1);
    --color-grey-50: rgba(158, 158, 158, 1);
    --color-grey-40: rgba(189, 189, 189, 1);
    --color-grey-30: rgba(224, 224, 224, 1);
    --color-grey-20: rgba(238, 238, 238, 1);
    --color-grey-10: rgba(250, 250, 250, 1);

    --color-success-main: rgba(76, 175, 80, 1);


    --color-white: white;
    --color-black: black;

    --color-text-default-1: hsla(0, 0%, 100%, .95);
    --color-text-default-2: rgba(226, 226, 226, 0.95);
    --color-text-default-3: hsla(225, 5%, 54%, 0.95);

    --color-text-inv-1: hsla(210, 5%, 8%, 0.96);
    --color-text-inv-2: hsla(220, 7%, 32%, 0.95);

    --color-background-1: hsl(230, 37%, 13%);

    --color-text-primary-1: var(--color-secondary-400);
    --color-text-primary-2: var(--color-secondary-500);
    --color-text-primary-3: var(--color-secondary-300);
    --color-text-secondary-2: var(--color-secondary-300);

    // Secondary Palette
    --color-shader: rgba(255, 255, 255, 0.04);

    --transparent-card-background: linear-gradient(108.05deg, rgba(33, 35, 47, 0.9) 3.43%, rgba(33, 35, 47, 0.1) 170.47%);
    --color-page-background: linear-gradient(180deg, #0F004A 0%, #13141B 100%);

}
// MUI
[data-theme="dark"] {
    --mui-background-default: white;
    --mui-background-paper: hsl(235, 23%, 32%);
    --mui-primary-main: white;
}


// Overall
[data-theme="dark"] {
    --logo-color: white;
    --page-background-color: hsl(236, 28%, 12%);
    --page-background: linear-gradient(180deg, #03007E 0%, #000E49 100%);
}


// Header (Navbar)
[data-theme="dark"] {
    --header-attop-background-color: hsl(233deg 31% 16% / 10%);
    --header-background-color: hsl(233deg 31% 16% / 60%);
    --header-backdrop-filter: blur(8px);
    --header-box-shadow: none;
    --header-color: white;
    --header-border-bottom: none;
    --header-chain-background-color: #6174ff;
    --header-chain-background-color-hover: rgb(110, 129, 250); 
}

// Sidebar
[data-theme="dark"] {
    --sidebar-background-color: hsl(233deg 31% 16% / 60%);
    --sidebar-backdrop-filter: blur(8px);
    --sidebar-border-right: 1px solid hsl(232, 41%, 35%);
    --sidebar-menu-background-hover: hsl(233, 31%, 22%);
    --sidebar-menu-background-active: linear-gradient(90deg, var(--color-secondary-500) -250%, hsl(233, 31%, 22%) 50%, hsl(233, 31%, 22%) 100%);

    --sidebar-menu-color: white;
    --sidebar-menu-color-active: var(--color-secondary-500);
    --sidebar-belt-color: var(--color-secondary-700);

    --sidebar-accordion-background: hsl(234, 31%, 12%);
}


// Card
[data-theme="dark"] {
    --card-background-color: hsl(232, 33%, 17%);
    --card-light-background: linear-gradient(108.51deg, hsl(232, 33%, 17%) 3.43%, hsl(232, 33%, 17%) 154.53%);
    --card-dark-gradient-background: linear-gradient(0.05deg, rgb(32 38 102) 3.43%, rgba(10, 16, 57, 0.1) 170.47%);
    --card-dark-gradient-border: none;
    --card-border: 1px solid hsl(232, 41%, 35%);
    --card-border-radius: 16px;
    --card-box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.05);
}

// Transparent Card
[data-theme="dark"] {
    --transparent-card-background: linear-gradient(108.51deg, rgba(29, 33, 58, 0.9) 3.43%, rgba(29, 33, 58, 0.1) 154.53%);
    --transparent-card-box-shadow: 10px 10px 30px rgba(187, 187, 255, 0.15);
    --transparent-card-backdrop-filter: blur(50px);
    --transparent-card-border: var(--card-border);
    --transparent-card-border-radius: var(--card-border-radius);

    --transparent-card-aura-background: linear-gradient(108.51deg, rgba(29, 33, 58, 0.8) 3.43%, rgba(29, 33, 58, 0.1) 154.53%);
    --transparent-card-aura-white-background: linear-gradient(108.51deg, rgba(255, 255, 255, 0.8) 3.43%, rgba(255, 255, 255, 0.3) 154.53%);
    --transparent-card-aura-shadow-color: rgba(187, 187, 255, 0.15);
}

// On Top Transparent Card
[data-theme="dark"] {
    --ontop-transparent-card-background: linear-gradient(108.51deg, rgba(205, 195, 248, 0.2) 3.43%, rgba(255, 255, 255, 0.1) 154.53%);
    --ontop-transparent-card-backdrop-filter: blur(1px);
    --ontop-transparent-card-border-radius: var(--card-border-radius);
    --ontop-transparent-card-wording-background: linear-gradient(108.51deg, rgb(44 31 101) 15%, #9262e8 90%);
}

// Button
[data-theme="dark"] {
    --button-background-default: var(--color-primary-main);
    --button-background-default-hover: var(--color-primary-800);
    --button-background-default-disable: var(--color-grey-40);
    --button-color-default: white;

    --button-color-secondary: var(--color-black);
    --button-background-secondary: var(--color-secondary-500);
    --button-background-secondary-hover: var(--color-secondary-400);
    --button-background-secondary-disable: var(--color-grey-20);
    --button-border-secondary: none;

    --button-color-tertiary: var(--color-white);
    --button-background-tertiary: transparent;
    --button-background-tertiary-hover: var(--color-grey-70);
    --button-background-tertiary-disable: var(--color-grey-20);
    --button-border-tertiary: 1px solid var(--color-grey-40);

    --button-color-ghost: var(--color-white);
    --button-color-ghost-disable: var(--color-text-default-3);
    --button-background-ghost: transparent;
    --button-background-ghost-hover: var(--color-base-800);
    --button-background-ghost-disable: var(--color-base-900);
    --button-border-ghost: 1px solid var(--color-grey-10);

    --button-color-outlined: var(--color-white);
    --button-color-outlined-disable: var(--color-text-default-3);
    --button-background-outlined: transparent;
    --button-background-outlined-hover: var(--color-base-800);
    --button-background-outlined-disable: var(--color-base-900);
    --button-border-outlined: 1px solid var(--color-grey-40);
}

// Input
[data-theme="dark"] {
    --input-outline-background: hsl(231, 41%, 10%);
    --input-outline-border: 1px solid hsl(231, 41%, 10%);
    --input-outline-border-hover: 1px solid rgba(255, 255, 255, 0.384);
}

// Hr
[data-theme="dark"] {
    --hr-background: hsl(234, 27%, 37%);
    --hr-height: 1px;
}

// Vt
[data-theme="dark"] {
    --vt-background: hsl(234, 27%, 37%);
    --vt-width: 1px;
}

// Link
[data-theme="dark"] {
    --link-color: var(--color-secondary-500);
    --link-color-hover: var(--color-secondary-600);
}


// Slate
[data-theme="dark"] {
    --slate-background: hsl(233, 42%, 12%);
}

// Farm
[data-theme="dark"] {
    --farm-filter-background: linear-gradient(108.51deg, hsl(232, 33%, 17%, 0.9) 3.43%, hsl(232, 33%, 17%, 9) 154.53%);
    --farm-poollist-background: linear-gradient(108.51deg, hsl(232, 33%, 17%, 0.4) 3.43%, hsl(232, 33%, 17%, 3) 154.53%);
    --farm-labels-border: 1px solid hsl(233, 27%, 32%);
    --link-color-hover: var(--color-secondary-600);
    --tag-dyor-background: var(--color-warning-600);
    --tag-dyor-background-hover: var(--color-warning-700);
    --farm-tag-new-background: linear-gradient(90deg, rgb(2, 0, 36) 0%, rgb(9, 9, 121) 25%, rgb(0, 212, 255) 100%);
    --farm-tag-new-color: var(--color-white);
}

// Collections
[data-theme="dark"] {
    // --collections-text-background: -webkit-linear-gradient(135deg, #B3FFAB 0%, #12FFF7 100%);
    --collections-text-color: #12FFF7;
    --collections-text-shadow: 0px 0px 5px rgb(92, 92, 92);
    --collections-empty-text-color: var(--color-text-default-1);
    --collections-card-number-background: rgba(240, 245, 255, 1);
    --collections-card-number-color: rgba(48, 79, 254, 1);
}

// Home > Card
[data-theme="dark"] {
    --funding-page-background: var(--color-page-background);
    --home-page-background: linear-gradient(180deg, #0F004A 0%, #13141B 100%);
    --home-title-text-color: var(--color-text-default-1);
    --home-incard-title-text-color: var(--color-text-default-1);
    --home-subtitle-text-color: rgba(158, 158, 158, 1);
    --home-transparent-card-background: var(--transparent-card-background);
    --home-transparent-card-white-background: linear-gradient(108.51deg, rgba(255, 255, 255, 0.7) 3.43%, rgba(255, 255, 255, 0.3) 154.53%);
    --home-transparent-card-shadow-color: rgba(187, 187, 255, 0.15);
    --home-transparent-logo-card-background: linear-gradient(108.05deg, rgba(20, 33, 53, 0.95) 3.43%, rgba(33, 35, 47, 0.1) 170.47%);
    --home-transparent-dark-card-background: linear-gradient(108.51deg, rgba(7, 30, 62, 0.95) 3.43%, rgba(7, 30, 62, 0.5) 154.53%);
    --home-solid-card-background: rgb(255, 255, 255);
}

// Order kafra
[data-theme="dark"] {
    --order-modal-background: var(--color-white);
    --order-modal-border-radius: 32px;

    --order-modal-header-padding: 20px 24px;
    --order-modal-header-color: var(--color-text-default-1);

    --order-modal-content-padding: 20px 24px 24px 24px;
    --order-modal-content-color: var(--color-text-default-1);

    --order-modal-token-list-item-bg-hover: var(--color-primary-50);

    --order-modal-token-image-margin-right: 16px;
    --order-modal-token-image-width: 24px;
    --order-modal-token-image-height: 24px;

    --order-modal-token-title-color: var(--color-primary-500);
    --order-modal-token-title-fontsize: 16px;
    --order-modal-token-title-margin-bottom: 4px;

    --order-modal-token-platform-color: var(--color-primary-400);
    --order-modal-token-platform-fontsize: 12px;

    --order-form-select-hover: var(--color-base-100);
    --order-form-select-border-color: var(--color-base-100);
    --order-form-input-border-color: var(--color-base-100);
}

// Modal
[data-theme="dark"] {
    --modal-overlay-background: var(--transparent-card-background);
    --modal-overlay-box-shadow: var(--card-box-shadow);
    // --modal-content-background: linear-gradient(180deg, #0F004A 0%, #13141B 100%);
    --modal-content-background: var(--card-background-color);
    --modal-content-border: 1px solid var(--color-base-400);
    // --modal-content-border: 1px solid rgba(19, 20, 27, 1);
}

// Scollbar
[data-theme="dark"] {
    --scrollbar-thumb-background: var(--color-primary-300);
    --scrollbar-track-shadow: var(--color-primary-800) 0px 0px 3px inset;
    --scrollbar-background: var(--page-background-color);
}

// Progress Circular
[data-theme="dark"] {
    --progress-circular-color: var(--color-secondary-500);
    --progress-circular-background-color: var(--color-grey-30);
}



//-------------------------
// Override MUI
//-------------------------
[data-theme="dark"] {
    // checkbox
    .MuiCheckbox.Mui-checked {
        .MuiSelect-icon,
        .MuiSvgIcon-root {
            fill: var(--color-secondary-500);
        }
    }

    // Input
    .MuiInput-underline:before {
        border-bottom: 1px solid white;

    }
    .MuiInputBase-root {
        .MuiSelect-icon,
        .MuiSvgIcon-root {
            fill: white;
        }
    }

    .MuiInput {
        .MuiSelect-icon,
        .MuiSvgIcon-root {
            fill: var(--color-text-default-1);
        }
    }

    [class^="PrivateValueLabel-label"] {
        color: white;
    }
    
    .MuiOutlinedInput-root, .MuiSelect-outlined {
        background: var(--input-outline-background);
        border-radius: 8px;
    }

    .MuiTabs-root:not(.ksw-tabs) {
        .MuiButtonBase-root {
            border-bottom: 1px solid var(--color-grey-20);

            &.Mui-selected {
                background-color: var(--color-secondary-600);
                font-weight: 600;
                color: var(--button-color-default);
            }
        }
        .MuiTabs-indicator {
            background-color: var(--color-white);
        }
    }

    .MuiPopover-paper {
        // background-color: transparent;
        border-radius: 10px;
        
    }
}