@import "src/styles/layout/config.scss";
@import "src/styles/components/_animation.scss";

.home-page{
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding-bottom: 24px;
  line-height: 1.5;
  scroll-behavior: smooth;
  img {
    // image-rendering: -webkit-optimize-contrast;
  }
  & > :not(.full-width){ 
    padding-left: calc(var(--main-padding-left) + var(--sidebar-width));
    padding-right: calc(var(--main-padding-right));
  }
  .full-width {
    width: 100%;
  }
  .top-section-backgroud-image {
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    height: 100%;
  }
}