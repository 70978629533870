.ksw-slate {
    background-color: var(--slate-background);
    border-radius: 8px;
    padding: 24px 16px;

    &.-layout, & .-layout {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 4px;
        grid-column-gap: 12px;
    }
}

.ksw-slate-input {
    background-color: var(--slate-input-background);
    box-shadow: var(--slate-input-box-shadow);
    border-radius: 16px;
    padding: 12px 24px;

    &.-layout, & .-layout {
        display: grid;
        grid-template-columns: minmax(100px, 1fr) auto;
        grid-row-gap: 6px;
        grid-column-gap: 12px;
    }
}
